export function customBtoa(string) {
  return string
    ? string
        .split("-")
        .map(item => btoa(item))
        .join("-")
    : null;
}

export function customAtob(string) {
  return string
    ? string
        .split("-")
        .map(item => atob(item))
        .join("-")
    : null;
}
