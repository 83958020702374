import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins";
import { Auth0Plugin } from "./plugins/auth0.js";

// TODO: FIX this
import "../css/rdx-editor.css";
import "@raffine/rdx-ui/lib/rdx-ui.css";
import "bootstrap/dist/css/bootstrap-grid.css";

Vue.config.productionTip = false;

Vue.use(Auth0Plugin, {
  domain: process.env["VUE_APP_AUTH0_DOMAIN"],
  clientId: process.env["VUE_APP_AUTH0_CLIENT_ID"],
  redirect_uri: process.env["VUE_APP_AUTH0_REDIRECT_URI"],
  audience: process.env["VUE_APP_AUTH0_AUDIENCE"],
  onRedirectCallback: appState => {
    window.history.replaceState({}, document.title, window.location.pathname);
    window.location.reload();
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

// interior utils
import format from "@/utils/format";
Object.defineProperty(Vue.prototype, "$format", { value: format });

new Vue({
  name: "CiApp",
  router,
  store,
  render: h => h(App)
}).$mount("#app");
