import Vue from "vue";
import vuexI18n from "vuex-i18n";

import store from "../store";

import en from "@/translation/en.json";

Vue.use(vuexI18n.plugin, store, {
  moduleName: "i18n",
  onTranslationNotFound(locale, key) {
    const ERROR = `i18n :: Key '${key}' not found for locale '${locale}'`;

    // for developers
    console.warn(ERROR);
  }
});

Vue.i18n.add("en", en);

Vue.i18n.fallback("en");

let language = navigator.language;

// Try finding locale by full langauge code first (for example, zh-TW)
// If not found, try finding by prefix only (for example, es)
if (language && Vue.i18n.localeExists(language, "fallback")) {
  store.commit("location/SET_LOCALE", language);
} else if (
  language &&
  Vue.i18n.localeExists(language.split("-")[0], "fallback")
) {
  store.commit("location/SET_LOCALE", language);
}

Vue.i18n.set(store.getters["location/getLocale"]);
