<template>
  <div class="ci-fullscreen-sidebar">
    <div class="ci-fullscreen-sidebar__header">
      <RdxButton rect @click="close">
        <RdxIcon class="ri-close-line rdx-icon--24" />
      </RdxButton>
    </div>
    <slot />
  </div>
</template>

<script>
import { RdxIcon, RdxButton } from "@raffine/rdx-ui/lib/rdx-ui.umd";
export default {
  name: "CiFullscreenSidebar",

  components: {
    RdxIcon,
    RdxButton
  },

  methods: {
    close() {
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="scss">
.ci-fullscreen-sidebar {
  display: block;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background: $grey-1;

  &__header {
    padding: 1rem;
    height: 64px;
    border-bottom: 1px solid $grey-5;
  }
}
</style>
