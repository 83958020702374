export default {
  price(price, locale = "en-US", currency = "USD") {
    price = parseFloat(price);
    return price.toLocaleString(locale, {
      style: "currency",
      currency
    });
  },

  date(time, locale = "en-US") {
    return new Date(time).toLocaleDateString(locale);
  }
};
