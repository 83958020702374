// module list
import vehicles from "./vehicles";
import listings from "./listings";
import buttons from "./buttons";
import buttonSet from "./buttonSets";
import location from "./location";
import sortFilter from "./sortFilter";

const modules = {
  vehicles,
  listings,
  buttons,
  buttonSet,
  location,
  sortFilter
};

export default modules;
