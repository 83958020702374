import Vue from "vue";

const storedCompanyUuid = window.localStorage.getItem("company_uuid");
const storedMainCompanyUuid = window.localStorage.getItem("main_company_uuid");

/**
 * request
 */
export async function requestConfig(config) {
  let companyUuid = window.localStorage.getItem("company_uuid");
  if (storedCompanyUuid && storedCompanyUuid !== companyUuid) {
    window.localStorage.setItem("company_uuid", storedCompanyUuid);
    Vue.$toast.info("Fixed company uuid");
  }

  let mainCompanyUuid = window.localStorage.getItem("main_company_uuid");
  if (storedMainCompanyUuid && storedMainCompanyUuid !== mainCompanyUuid) {
    window.localStorage.setItem("main_company_uuid", storedMainCompanyUuid);
    Vue.$toast.info("Fixed company uuid");
  }

  config.headers.post["Content-Type"] = "application/x-www-form-urlencoded";
  config.headers.common["Accept"] = "application/json";
  config.headers.common["Content-Type"] = "application/json";
  config.headers.common["Access-Control-Allow-Credentials"] = "true";

  let token = window.localStorage.getItem("token");

  config.headers.common["Authorization"] = `Bearer ${token}`;

  return config;
}

export function requestErrorHandler(error) {
  return Promise.reject(error);
}

/**
 * response
 */
export function responseHandler(response) {
  return response;
}

export function responseErrorHandler(error) {
  const response = error.response;
  if (response) {
    if (response.data && response.data.errors) {
      const { errors } = response.data;
      errors.forEach(error => {
        Vue.$toast.error(error.detail || error.title);
      });
    }

    // if (response.status === 404) {
    //   Vue.$toast.error(error);
    // }

    // if (response.status === 422) {
    //   Vue.$toast.error(error);
    // }

    // if (response.status === 500) {
    //   Vue.$toast.error(error);
    // }
  }

  return Promise.reject(error);
}
