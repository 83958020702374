import { authGuard } from "../plugins/authGuard";

const STOCK_VIEW = () =>
  import(
    /* webpackChunkName: "stock" */
    "../views/cars-inventory/stock/Stock.vue"
  );

export default [
  {
    path: "/",
    redirect: "/cars-inventory"
  },
  {
    path: "/cars-inventory",
    component: () =>
      import(
        /* webpackChunkName: "cars-inventory" */
        "../views/cars-inventory/index.vue"
      ),
    beforeEnter: authGuard,
    children: [
      {
        path: "/",
        redirect: "stock"
      },
      {
        path: "stock",
        name: "CarsInventoryStock",
        component: STOCK_VIEW,
        beforeEnter: authGuard,
        children: [
          { path: ":id", name: "CarsInventoryStockID", component: STOCK_VIEW }
        ]
      },
      {
        path: "publications",
        name: "CarsInventoryPublications",
        component: () =>
          import(
            /* webpackChunkName: "publications" */
            "../views/cars-inventory/publications/Publications.vue"
          ),
        beforeEnter: authGuard
      },
      {
        path: "settings",
        name: "CarsInventorySettings",
        component: () =>
          import(
            /* webpackChunkName: "settings" */
            "../views/cars-inventory/settings/Settings.vue"
          ),
        beforeEnter: authGuard,
        children: [
          {
            path: "profile",
            name: "CarsInventorySettingsProfile",
            component: () =>
              import(
                /* webpackChunkName: "settings-profile" */
                "../views/cars-inventory/settings/profile.vue"
              ),
            beforeEnter: authGuard
          },
          {
            path: "location",
            name: "CarsInventorySettingsLocation",
            component: () =>
              import(
                /* webpackChunkName: "settings-location" */
                "../views/cars-inventory/settings/location.vue"
              ),
            beforeEnter: authGuard
          },
          {
            path: "option-codes-library",
            name: "Dictionary",
            component: () =>
              import(
                /* webpackChunkName: "settings-library" */
                "../views/cars-inventory/dictionary/Dictionary.vue"
              ),
            beforeEnter: authGuard
          },
          {
            path: "units",
            name: "CarsInventorySettingsUnits",
            component: () =>
              import(
                /* webpackChunkName: "settings-units" */
                "../views/cars-inventory/settings/units.vue"
              ),
            beforeEnter: authGuard
          }
        ]
      },
      {
        path: "button-sets",
        name: "CarsInventoryButtonSets",
        component: () =>
          import(
            /* webpackChunkName: "button-sets" */
            "../views/cars-inventory/buttons/ButtonSetsList.vue"
          ),
        beforeEnter: authGuard
      },
      {
        path: "discounts",
        name: "CarsInventoryDiscounts",
        component: () =>
          import(
            /* webpackChunkName: "button-sets" */
            "../views/cars-inventory/discounts/Discounts.vue"
          ),
        beforeEnter: authGuard
      },
      {
        path: "dictionary",
        name: "CarsInventoryDictionary",
        component: () =>
          import(
            /* webpackChunkName: "settings" */
            "../views/cars-inventory/dictionary/Dictionary.vue"
          ),
        beforeEnter: authGuard
      },
      {
        path: "login",
        name: "login",
        component: () =>
          import(
            /* webpackChunkName: "login" */
            "../views/cars-inventory/auth/login.vue"
          )
      }
    ]
  },
  {
    path: "/cars-inventory/vehicle/new",
    name: "CarsInventoryNewVehicle",
    component: () =>
      import(
        /* webpackChunkName: "new-vehicle" */
        "../views/cars-inventory/vehicle/new-vehicle/NewVehicle.vue"
      ),
    beforeEnter: authGuard
  },
  {
    path: "/cars-inventory/vehicle/:id",
    name: "CarsInventoryVehicleDetails",
    component: () =>
      import(
        /* webpackChunkName: "vehicle-details" */
        "../views/cars-inventory/vehicle/VehicleDetails.vue"
      ),
    beforeEnter: authGuard
  },
  {
    path: "/cars-inventory/vehicle/:id/preview",
    name: "CarsInventoryVehicleDetailsPreview",
    component: () =>
      import(
        /* webpackChunkName: "vehicle-details" */
        "../views/cars-inventory/vehicle/VehicleDetails.vue"
      ),
    beforeEnter: authGuard
  },
  {
    path: "/cars-inventory/vehicle/:id/edit",
    name: "CarsInventoryVehicleEdit",
    component: () =>
      import(
        /* webpackChunkName: "vehicle-edit" */
        "../views/cars-inventory/vehicle/VehicleEdit.vue"
      ),
    beforeEnter: authGuard
  },
  {
    path: "/cars-inventory/vehicle/:id/duplicate",
    name: "CarsInventoryVehicleDuplicate",
    component: () =>
      import(
        /* webpackChunkName: "vehicle-duplicate" */
        "../views/cars-inventory/vehicle/VehicleDuplicate.vue"
      ),
    beforeEnter: authGuard
  },
  {
    path: "/cars-inventory/publications/new-list",
    name: "CarsInventoryNewList",
    component: () =>
      import(
        /* webpackChunkName: "new-list" */
        "../views/cars-inventory/publications/NewList.vue"
      ),
    beforeEnter: authGuard
  },
  {
    path: "/cars-inventory/publications/:id",
    name: "CarsInventoryEditList",
    component: () =>
      import(
        /* webpackChunkName: "publications-edit-list" */
        "../views/cars-inventory/publications/EditList.vue"
      ),
    beforeEnter: authGuard
  },
  {
    path: "/cars-inventory/button-set/new",
    name: "CarsInventoryButtonSetNew",
    component: () =>
      import(
        /* webpackChunkName: "button-set-new" */
        "../views/cars-inventory/buttons/NewButtonSet.vue"
      ),
    beforeEnter: authGuard
  },
  {
    path: "/cars-inventory/button-set/:id/edit",
    name: "CarsInventoryButtonSetEdit",
    component: () =>
      import(
        /* webpackChunkName: "button-set-new" */
        "../views/cars-inventory/buttons/EditButtonSet.vue"
      ),
    beforeEnter: authGuard
  },
  {
    path: "/cars-inventory/button-set/:id/duplicate",
    name: "CarsInventoryButtonSetDuplicate",
    component: () =>
      import(
        /* webpackChunkName: "button-set-new" */
        "../views/cars-inventory/buttons/DuplicateButtonSet.vue"
      ),
    beforeEnter: authGuard
  },
  {
    path: "*",
    redirect: "/"
  }
];
