export default {
  state: ["state", "_eq"],
  brand: ["nameplate_brand_name", "_eq"],
  model: ["nameplate_name", "_eq"],
  listing: ["listing_id", "_eq"],
  engine: ["engine_type", "_includes"],
  color: ["color_canonical", "_eq"],
  availability: ["status", "_eq"],
  yearFrom: ["production_year", "_gteq"],
  yearTo: ["production_year", "_lteq"],
  mileageFrom: ["mileage", "_gteq"],
  mileageTo: ["mileage", "_lteq"],
  priceFrom: ["msrp_price", "_gteq"],
  priceTo: ["msrp_price", "_lteq"]
};
