<template>
  <div v-if="!$auth.loading && $auth.user" class="ci-user">
    <img class="ci-user__image" :src="$auth.user.picture" />
    <RdxDropdown
      side="right"
      trigger="ri-arrow-down-s-line rdx-icon--24 ci-user__dropdown-trigger"
    >
      <div class="ci-user__dropdown-content">
        <div class="ci-user__dropdown-item ci-user__name">
          {{ $auth.user.name }}
        </div>
        <RdxButton
          class="ci-user__dropdown-item ci-user__log-out"
          block
          filled
          variant="grey"
          @click="logout"
        >
          <span>{{ $t("user-panel.log-out") }} </span>
          <RdxIcon icon="ri-logout-circle-r-line" />
        </RdxButton>
      </div>
    </RdxDropdown>
  </div>
</template>

<script>
import {
  RdxDropdown,
  RdxButton,
  RdxIcon
} from "@raffine/rdx-ui/lib/rdx-ui.umd";

export default {
  name: "CiUser",

  components: {
    RdxDropdown,
    RdxButton,
    RdxIcon
  },

  methods: {
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    }
  }
};
</script>

<style lang="scss">
.ci-user {
  display: flex;
  margin-right: 20px;
  align-items: center;

  &__dropdown-trigger {
    padding: 8px;
  }
  &__dropdown-content {
    padding: 10px;
  }
  &__dropdown-item {
    display: block;
    padding-bottom: 10px;
  }

  &__image {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }

  &__log-out {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
