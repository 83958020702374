import http from "../http";

function initialState() {
  return {
    all: []
  };
}
const state = initialState();

const getters = {
  getAll(state) {
    return state.all.sort(({ id: a }, { id: b }) => parseInt(b) - parseInt(a));
  },
  getById(state) {
    return id => state.all.find(listing => listing.id === id);
  }
};

const actions = {
  async fetchAll({ commit }) {
    const {
      data: { data }
    } = await http.get("listings");
    commit("SET_ALL", data);
    return data;
  },
  async create({ dispatch }, payload) {
    const {
      data: { data }
    } = await http.post("listings", payload);
    await dispatch("fetchAll");
    return data;
  },
  async update({ dispatch }, { id, payload }) {
    const {
      data: { data }
    } = await http.patch(`listings/${id}`, payload);
    await dispatch("fetchAll");
    return data;
  },
  async delete({ dispatch }, id) {
    const {
      data: { data }
    } = await http.delete(`listings/${id}`);
    await dispatch("fetchAll");
    return data;
  },
  async addVehicleToListing({ dispatch }, { listingId, vehicleId }) {
    const {
      data: { data }
    } = await http.put(`listings/${listingId}/vehicle/${vehicleId}`);
    await dispatch("fetchAll");
    return data;
  },
  async removeVehicleToListing({ dispatch }, { listingId, vehicleId }) {
    const {
      data: { data }
    } = await http.delete(`listings/${listingId}/vehicle/${vehicleId}`);
    await dispatch("fetchAll");
    return data;
  }
};

const mutations = {
  SET_ALL(state, listings) {
    state.all = listings;
  },
  // eslint-disable-next-line
  RESET_STATE(state) {
    state = Object.assign(state, initialState);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
