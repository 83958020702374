<template>
  <div id="app">
    <CiNavigation :nav-slim="navSlim" />
    <CiTopBar :nav-slim="navSlim" @nav-toggle="navToggle" />
    <router-view
      v-if="!openInFullscreenModal"
      class="content-wrapper"
      :class="{ 'content-wrapper--slim-nav': navSlim }"
    />

    <template v-if="openInFullscreenModal">
      <CiFSSB>
        <router-view />
      </CiFSSB>
    </template>
  </div>
</template>

<script>
import CiFSSB from "@/components/CiFullscreenSidebar/CiFullscreenSidebar.vue";
import CiNavigation from "@/components/CiNavigation/CiNavigation.vue";
import CiTopBar from "@/components/CiTopBar/CiTopBar.vue";

export default {
  name: "CiApp",

  components: {
    CiFSSB,
    CiNavigation,
    CiTopBar
  },

  data() {
    return {
      navSlim: false
    };
  },

  computed: {
    openInFullscreenModal() {
      const routeNames = ["CarsInventoryVehicleDetails"];
      return !!routeNames.includes(this.$route.name);
    }
  },

  methods: {
    navToggle(slim) {
      this.navSlim = slim;
    }
  }
};
</script>

<style lang="scss">
* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Inter";
  font-size: 14px;
  height: 100%;
}

#app {
  height: 100%;
}

.content-wrapper {
  margin-left: 200px;
  transition: margin 0.3s;

  &--slim-nav {
    margin-left: 88px;
  }
}
</style>
