import qs from "qs";
import FILTER_ATTRS from "@/components/CiFilters/_filters-attrs";

const state = initialState();

const getters = {
  sortSetting: state => state.sort,
  filterSetting: state => state.filter,
  vinNumber: () => state.sort.vin_start,

  string: state => {
    let filterEntries = Object.entries(state.filter).map(([filter, data]) => {
      return FILTER_ATTRS[filter]
        ? [
            [`${FILTER_ATTRS[filter][0] + FILTER_ATTRS[filter][1]}`],
            data && data.value ? data.value : null
          ]
        : console.error(
            `[CiFilters]: Missing "${filter}" key in FILTERS_ATTRS`
          );
    });

    let options = {
      filter: Object.fromEntries(filterEntries)
    };

    if (state.sort?.sort) options["sort"] = state.sort.sort;
    if (state.sort?.vin_start)
      options["filter[vin_or_equipment_no_eq]"] = state.sort.vin_start;

    return "&" + qs.stringify(options, { encode: false });
  }
};

const mutations = {
  SET_FILTER_STATE(state, payload) {
    state.filter = { ...payload };
    saveUserSettings("user-filters", payload);
  },
  SET_SORT_STATE(state, payload) {
    state.sort = { ...payload };
    saveUserSettings("user-sort", payload);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
};

function getCompanyUuid() {
  return localStorage.getItem("company_uuid");
}

function getSavedState(key) {
  let localStorageItem = localStorage.getItem(key);
  return localStorageItem ? JSON.parse(localStorageItem) : {};
}

function getCompanySavedState(key) {
  let company_uuid = getCompanyUuid();
  let savedState = getSavedState(key);
  return savedState[company_uuid];
}

function saveUserSettings(key, data) {
  let company_uuid = getCompanyUuid();
  let savedState = getSavedState(key);

  savedState[company_uuid] = data;
  localStorage.setItem(key, JSON.stringify(savedState));
}

function initialState() {
  let filter = {};
  let sort = {
    sort: "-created_at"
  };

  writeSavedStateToStore(filter, "user-filters");
  writeSavedStateToStore(sort, "user-sort");

  return {
    filter,
    sort
  };
}

function writeSavedStateToStore(to, localStorageKey) {
  let savedFilterSettings = getCompanySavedState(localStorageKey);
  for (let key in savedFilterSettings) {
    to[key] = savedFilterSettings[key];
  }
}
