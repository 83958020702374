import Vue from "vue";
import axios from "axios";
import {
  requestConfig,
  requestErrorHandler,
  responseHandler,
  responseErrorHandler
} from "./interceptors";

import { customAtob } from "./base64";

const HTTP = axios.create({
  baseURL: process.env["VUE_APP_API_URL"],
  headers: {
    "Content-Type": "application/vnd.api+json",
    "X-Company-UUID": customAtob(window.localStorage.getItem("company_uuid"))
  }
});

HTTP.interceptors.request.use(requestConfig, requestErrorHandler);
HTTP.interceptors.response.use(responseHandler, responseErrorHandler);

Vue.prototype.$http = HTTP;
Vue.prototype.http = HTTP;
Vue.prototype.$axios = HTTP;
Vue.prototype.axios = HTTP;

export default HTTP;
