import Vue from "vue";
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize
} from "vee-validate";
import * as rules from "vee-validate/dist/rules";

import { parse } from "vue-currency-input";

// register compoenets globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

// register all rules globally
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

// load locale for user browser language
const AVAILABLE_TRANSLATIONS = ["pl", "en", "es"];
const DEFAULT_LANGUAGE = "en";
const LANGUAGE = navigator.language.split("-")[0] || DEFAULT_LANGUAGE;

loadLocale(
  AVAILABLE_TRANSLATIONS.includes(LANGUAGE) ? LANGUAGE : DEFAULT_LANGUAGE
);

function loadLocale(code) {
  return import(`vee-validate/dist/locale/${code}.json`).then(locale => {
    localize(code, locale);
  });
}

function getNumberValue(value, currencyFormat) {
  return Number(value) ? Number(value) : parse(value, currencyFormat);
}

extend("lower_than", {
  params: ["target", "currencyFormat"],
  validate(value, { target, currencyFormat }) {
    return (
      getNumberValue(value, currencyFormat) <
      getNumberValue(target, currencyFormat)
    );
  },
  message: `{_field_} ${Vue.i18n.translate(
    "stock.shared-form.must-be-lower-than"
  )} {target}`
});

extend("is_positive", {
  params: ["currencyFormat"],
  validate(value, { currencyFormat }) {
    return getNumberValue(value, currencyFormat) > 0;
  },
  message: `{_field_} ${Vue.i18n.translate(
    "stock.shared-form.must-be-greater-than"
  )} 0`
});
