<template>
  <nav class="ci-navigation" :class="{ 'ci-navigation--slim': navSlim }">
    <div class="ci-navigation__logo">
      <CiLogoFull v-if="!navSlim" />
      <CiLogoMin v-if="navSlim" />
    </div>
    <div class="ci-navigation__wrapper">
      <!-- <div class="ci-navigation__link">
        <i class="ri-dashboard-line"></i>
        <span>Dashboard</span>
      </div> -->
      <!-- <div class="ci-navigation__link">
        <i class="ri-pulse-line"></i>
        <span>Activity Feed</span>
      </div> -->

      <!-- Content -->
      <!-- <div class="ci-navigation__group-title">Content</div> -->
      <!-- <div class="ci-navigation__link">
        <i class="ri-organization-chart 1"></i>
        <span>Websites</span>
      </div> -->

      <!-- Tools -->
      <div class="ci-navigation__group-title">{{ $t("sidenav.tools") }}</div>
      <router-link
        :to="{ name: 'CarsInventoryStock' }"
        tag="div"
        class="ci-navigation__link ci-navigation__link--active"
      >
        <i class="ri-car-line"></i>
        <span>CarsInventory</span>
      </router-link>

      <!-- Developers -->
      <!-- <div class="ci-navigation__group-title">Developers</div> -->
      <!-- <div class="ci-navigation__link">
        <i class="ri-server-line"></i>
        <span>Servers</span>
      </div> -->
      <!-- <div class="ci-navigation__link">
        <i class="ri-shield-check-line"></i>
        <span>Healthcheck</span>
      </div> -->
      <!-- <div class="ci-navigation__link">
        <i class="ri-signal-tower-fill"></i>
        <span>Updates</span>
      </div> -->
      <!-- <div class="ci-navigation__link">
        <i class="ri-apps-2-line"></i>
        <span>Domains</span>
      </div> -->
      <!-- <div class="ci-navigation__link">
        <i class="ri-fingerprint-fill"></i>
        <span>SSL</span>
      </div> -->
    </div>
  </nav>
</template>

<script>
import CiLogoFull from "@/components/atoms/CiLogoFull.vue";
import CiLogoMin from "@/components/atoms/CiLogoMin.vue";
export default {
  name: "CiNavigation",

  components: {
    CiLogoFull,
    CiLogoMin
  },

  props: {
    navSlim: {
      default: false,
      type: Boolean
    }
  }
};
</script>

<style lang="scss">
.ci-navigation {
  width: 200px;
  height: 100%;
  border: 1px solid $grey-3;
  position: fixed;
  z-index: 10;
  top: 0;
  right: auto;
  bottom: 0;
  left: 0;
  background-color: $grey-1;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
  transition: width 0.3s;

  &__logo {
    height: 64px;
    display: flex;
    align-items: center;
    padding: 0 24px;
  }

  &__link {
    width: 100%;
    height: 40px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    color: $grey-8;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    i {
      margin-right: 10px;
    }

    &:hover,
    &--active {
      color: $blue-6;
      background: $blue-1;
      border-right: 3px solid $blue-6;
      cursor: pointer;
    }
  }

  &--slim {
    width: 88px;
  }

  &--slim &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;

    i {
      font-size: 24px;
      margin: 0;
    }
    span {
      display: none;
    }
  }

  &__group-title {
    display: flex;
    align-items: center;
    margin-top: 24px;
    padding: 0px 24px;
    font-size: 12px;
    height: 30px;
    color: $grey-7;
  }

  &--slim &__group-title {
    opacity: 0;
  }
}
</style>
