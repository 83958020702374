import http from "../http";

function initialState() {
  return {
    all: []
  };
}
const state = initialState();

const getters = {
  getAll(state) {
    return state.all;
  },
  getById(state) {
    return id => state.all.find(button => button.id === id);
  },
  getPrimaryButton(state) {
    return state.all.find(
      ({ attributes }) => attributes.button_type === "primary"
    );
  },
  getSecondaryButtons(state) {
    return state.all.filter(
      ({ attributes }) => attributes.button_type === "secondary"
    );
  }
};

const actions = {
  async fetchAll({ commit }) {
    const {
      data: { data }
    } = await http.get("buttons");
    commit("SET_ALL", data);
    return data;
  },
  async create({ dispatch }, payload) {
    const {
      data: { data }
    } = await http.post("buttons", payload);
    await dispatch("fetchAll");
    return data;
  },
  async update({ dispatch }, { id, payload }) {
    const {
      data: { data }
    } = await http.patch(`buttons/${id}`, payload);
    await dispatch("fetchAll");
    return data;
  },
  async delete({ dispatch }, id) {
    const {
      data: { data }
    } = await http.delete(`buttons/${id}`);
    await dispatch("fetchAll");
    return data;
  }
};

const mutations = {
  SET_ALL(state, buttons) {
    state.all = buttons;
  },
  // eslint-disable-next-line
  RESET_STATE(state) {
    state = Object.assign(state, initialState);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
