<template>
  <div class="ci-top-bar" :class="{ 'ci-top-bar--nav-slim': navSlim }">
    <div class="ci-top-bar__nav-toggle" @click="toggle">
      <i v-if="!navSlim" class="ri-menu-fold-line"></i>
      <i v-if="navSlim" class="ri-menu-unfold-line"></i>
    </div>
    <CiUser />
  </div>
</template>

<script>
import CiUser from "../molecules/CiUser";

export default {
  name: "CiTopBar",
  components: {
    CiUser
  },

  props: {
    navSlim: {
      default: false,
      type: Boolean
    }
  },

  methods: {
    toggle() {
      this.$emit("nav-toggle", !this.navSlim);
    }
  }
};
</script>

<style lang="scss">
.ci-top-bar {
  width: 100%;
  height: 64px;
  border-bottom: 1px solid $grey-5;
  padding-left: 200px;
  color: $grey-8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: padding 0.3s;

  &--nav-slim {
    padding-left: 88px;
  }

  &__nav-toggle {
    font-size: 24px;
    padding: 1.2em 1em 0.8em 1em;

    &:hover {
      color: $blue-6;
      cursor: pointer;
    }
  }
}
</style>
