import http from "../http";

function initialState() {
  return {
    all: []
  };
}
const state = initialState();

const getters = {
  getAll(state) {
    return state.all;
  },
  getById(state) {
    return id => state.all.find(set => set.id === id);
  }
};

const actions = {
  async fetchAll({ commit }) {
    const { data } = await http.get("button_sets");
    commit("SET_ALL", data.data);
    return data;
  },
  // eslint-disable-next-line
  async fetchById({ commit }, id) {
    return await http.get(`button_sets/${id}`);
  },
  async create({ dispatch }, payload) {
    const {
      data: { data }
    } = await http.post("button_sets", payload);
    await dispatch("fetchAll");
    return data;
  },
  async update({ dispatch }, { id, payload }) {
    const response = await http.patch(`button_sets/${id}`, payload);
    await dispatch("fetchAll");
    return response;
  },
  async delete({ commit }, id) {
    const {
      data: { data }
    } = await http.delete(`button_sets/${id}`);
    commit("REMOVE_BY_ID", id);
    return data;
  }
};

const mutations = {
  SET_ALL(state, buttons) {
    state.all = buttons;
  },
  REMOVE_BY_ID(state, id) {
    let set = state.all.find(set => set.id === id);
    let index = state.all.indexOf(set);
    state.all.splice(index, 1);
  },
  // eslint-disable-next-line
  RESET_STATE(state) {
    state = Object.assign(state, initialState);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
