function initialState() {
  return {
    locale: "en"
  };
}
const state = initialState();

const getters = {
  getLocale(state) {
    return state.locale;
  }
};

const mutations = {
  SET_LOCALE(state, locale) {
    state.locale = locale;
  },
  // eslint-disable-next-line
  RESET_STATE(state) {
    state = Object.assign(state, initialState);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
};
