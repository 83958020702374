import axios from "axios";
// import api from '../api'

function initialState() {
  return {
    all: []
  };
}
const state = initialState();

const getters = {};

const actions = {
  async fetchAll() {
    const response = await axios.get("vehicles");
    return response;
  }
};

const mutations = {
  // eslint-disable-next-line
  RESET_STATE(state) {
    state = Object.assign(state, initialState);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
